import React, { useState } from 'react';
import './App.css';
import PalmTreeIcon from './palm-tree-icon.svg'; // Import the SVG

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="App">
      <nav className="navbar">
        {/* Logo or Company Name on the Left */}
        <div className="navbar-logo">
          cravity
          <img src={PalmTreeIcon} alt="Palm Tree Icon" className="navbar-icon" />
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="hamburger-icon" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        {/* Background Overlay */}
        <div className={`overlay ${menuOpen ? 'active' : ''}`} onClick={closeMenu}></div>

        {/* Sliding Navbar Links with Close Button */}
        <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={closeMenu}>✕</button>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#contact">Contact</a>
        </div>
      </nav>
    </div>
  );
}

export default App;
